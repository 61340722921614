exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dokumenty-js": () => import("./../../../src/pages/dokumenty.js" /* webpackChunkName: "component---src-pages-dokumenty-js" */),
  "component---src-pages-dziekuje-js": () => import("./../../../src/pages/dziekuje.js" /* webpackChunkName: "component---src-pages-dziekuje-js" */),
  "component---src-pages-fizjoterapia-bolu-plecow-js": () => import("./../../../src/pages/fizjoterapia-bolu-plecow.js" /* webpackChunkName: "component---src-pages-fizjoterapia-bolu-plecow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kwalifikacje-js": () => import("./../../../src/pages/kwalifikacje.js" /* webpackChunkName: "component---src-pages-kwalifikacje-js" */),
  "component---src-pages-masaz-leczniczy-js": () => import("./../../../src/pages/masaz-leczniczy.js" /* webpackChunkName: "component---src-pages-masaz-leczniczy-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-studia-js": () => import("./../../../src/pages/regulamin-studia.js" /* webpackChunkName: "component---src-pages-regulamin-studia-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-pages-trening-medyczny-js": () => import("./../../../src/pages/trening-medyczny.js" /* webpackChunkName: "component---src-pages-trening-medyczny-js" */),
  "component---src-pages-trening-personalny-js": () => import("./../../../src/pages/trening-personalny.js" /* webpackChunkName: "component---src-pages-trening-personalny-js" */),
  "component---src-pages-wizyta-js": () => import("./../../../src/pages/wizyta.js" /* webpackChunkName: "component---src-pages-wizyta-js" */),
  "component---src-pages-wizytowka-js": () => import("./../../../src/pages/wizytowka.js" /* webpackChunkName: "component---src-pages-wizytowka-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-tag-js": () => import("./../../../src/templates/blog-tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */)
}

